import React, { useState } from "react"

import Flow, { nodeWithValue } from "../../components/flows/flow"
import Layout from "../../components/layout"
import RRAnalytics from "../../analytics/rr_analytics"
import Seo from "../../components/seo"
import { useImpression } from "../../analytics/utils"
import "./flow.scss"

class Node {
  constructor(value, options) {
    this.value = value
    this.options = options
  }
}

class FlowChart {
  constructor() {
    this.nodes = Flow.map(({ value, options }) => new Node(value, options))
  }

  get startingNode() {
    return this.nodes[0]
  }
}

const Question = ({ children }) => <div className="question">{children}</div>
const Option = ({ children, onClick }) => (
  <button className="option" onClick={onClick}>
    {children}
  </button>
)

const FlowComponent = ({ location }) => {
  useImpression("EvilFlow")

  const flowChart = new FlowChart()
  const [step, setStep] = useState(flowChart.startingNode)

  const nextStep = ({ nextStep }) => {
    const nextNode = nodeWithValue(nextStep)
    if (nextNode) {
      setStep(nextNode)
    }
  }
  const onOptionClick = (option, stepValue) => {
    nextStep(option)
    RRAnalytics.trackEvent("Flow", "OptionClick", {
      option: option.value,
      prompt: stepValue,
    })
  }
  const onRedoClick = () => {
    setStep(flowChart.startingNode)
    RRAnalytics.trackEvent("Flow", "RedoClick")
  }

  return (
    <Layout location={location}>
      <Seo
        title="God versus Evil: Why does evil exist?"
        description="If God really is all-knowing, all-powerful, and all-loving then why does evil still exist in this world?"
      />
      <div className="d-flex justify-center align-center flow-wrapper">
        <div className="width-100">
          <Question>{step.value}</Question>
        </div>
        {step.options.map((option, i) => (
          <Option
            key={`option-${i}`}
            onClick={args => onOptionClick(option, step.value)}
          >
            {option.value}
          </Option>
        ))}
        {step.options.length === 0 && (
          <Option onClick={onRedoClick}>Redo</Option>
        )}
      </div>
      <p>
        Evil exists in this world whether we like it or not. Religious believers
        often say that their God is omnipotent (all-powerful), omniscient
        (all-knowing), and all-loving. Yet, if he really is all of these things
        then why does evil still exist?
      </p>
      <p>
        Answer just a few questions and you'll quickly see how the argument of
        an all-knowing, all-powerful, and all-loving God falls apart.
      </p>
    </Layout>
  )
}

export default FlowComponent
