export const nodeWithValue = valueToMatch =>
  Flow.find(({ value }) => value === valueToMatch)

const EvilFlow = {
  CAN_GOD_PREVENT_EVIL: "Can God stop evil?",
  DOES_GOD_KNOW_ALL_EVIL:
    "Does God know all the evil that exists in the world?",
  DOES_GOD_WANT_TO_STOP_EVIL:
    "Does God want to stop all of the evil that exists in the world?",
  WHY_IS_THERE_EVIL: "So why is there evil?",
  COULD_WE_NOT_HAVE_EVIL: "Could God have made a world without evil in it?",
  WHY_NOT: "Why didn't God create a world without evil?",

  NOT_ALL_POWERFUL: "Then God must not be all powerful.",
  NOT_ALL_KNOWING: "Then God must not be all knowing.",
  NOT_ALL_GOOD: "Then God must not be all good.",
  ALL_KNOWING_NO_NEED_TO_TEST:
    "But if God is all knowing, he would know what we would do if we were tested, so there wouldn't be any need to test us at all.",
  SATAN_IS_WEAK:
    "An all knowing, all powerful, and all loving God could and would destroy Satan.",
  FREE_WILL: "Could God have made the world with free will, but without evil?",
}

const Flow = [
  {
    value: EvilFlow.CAN_GOD_PREVENT_EVIL,
    options: [
      { value: "Yes", nextStep: EvilFlow.DOES_GOD_KNOW_ALL_EVIL },
      { value: "No", nextStep: EvilFlow.NOT_ALL_POWERFUL },
    ],
  },
  {
    value: EvilFlow.DOES_GOD_KNOW_ALL_EVIL,
    options: [
      { value: "Yes", nextStep: EvilFlow.DOES_GOD_WANT_TO_STOP_EVIL },
      { value: "No", nextStep: EvilFlow.NOT_ALL_KNOWING },
    ],
  },
  {
    value: EvilFlow.DOES_GOD_WANT_TO_STOP_EVIL,
    options: [
      { value: "Yes", nextStep: EvilFlow.WHY_IS_THERE_EVIL },
      { value: "No", nextStep: EvilFlow.NOT_ALL_GOOD },
    ],
  },
  {
    value: EvilFlow.WHY_IS_THERE_EVIL,
    options: [
      {
        value: "It's a test from God",
        nextStep: EvilFlow.ALL_KNOWING_NO_NEED_TO_TEST,
      },
      { value: "Satan", nextStep: EvilFlow.SATAN_IS_WEAK },
      {
        value:
          "It's a necessary thing that exists in the universe (or some other reason)",
        nextStep: EvilFlow.COULD_WE_NOT_HAVE_EVIL,
      },
    ],
  },
  {
    value: EvilFlow.COULD_WE_NOT_HAVE_EVIL,
    options: [
      { value: "Yes", nextStep: EvilFlow.WHY_NOT },
      { value: "No", nextStep: EvilFlow.NOT_ALL_POWERFUL },
    ],
  },
  {
    value: EvilFlow.ALL_KNOWING_NO_NEED_TO_TEST,
    options: [],
  },
  {
    value: EvilFlow.NOT_ALL_POWERFUL,
    options: [],
  },
  {
    value: EvilFlow.NOT_ALL_KNOWING,
    options: [],
  },
  {
    value: EvilFlow.NOT_ALL_GOOD,
    options: [],
  },
  {
    value: EvilFlow.SATAN_IS_WEAK,
    options: [],
  },
  {
    value: EvilFlow.WHY_NOT,
    options: [
      { value: "To test us", nextStep: EvilFlow.ALL_KNOWING_NO_NEED_TO_TEST },
      { value: "Because we have free-will", nextStep: EvilFlow.FREE_WILL },
    ],
  },
  {
    value: EvilFlow.FREE_WILL,
    options: [
      { value: "Yes", nextStep: EvilFlow.WHY_NOT },
      { value: "No", nextStep: EvilFlow.NOT_ALL_POWERFUL },
    ],
  },
]

export default Flow
